import { FormikValues } from 'formik';
import { FC, useCallback } from 'react';

import Checkbox from '@controls/Checkbox';
import Form from '@controls/Form';
import Password from '@controls/Password';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { Button, Layout, colors, scale, typography } from '@scripts/gds';

import { signInFormSchema } from './schema';

interface SignInFormProps {
    toggleForm: () => void;
    logIn: (params: { login: string; password: string }) => void;
    closeHandler: () => void;
    errorMessage: string;
}

export const SignInForm: FC<SignInFormProps> = ({ logIn, toggleForm, closeHandler, errorMessage }) => {
    const handlerSubmit = useCallback(async (vals: FormikValues) => {
        await logIn({ login: vals.login.trim(), password: vals.password });

        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
            window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'new_registration');
        }
    }, []);

    return (
        <Form
            onSubmit={handlerSubmit}
            initialValues={{ login: '', password: '', rememberMe: false }}
            validationSchema={signInFormSchema}
        >
            {({
                values: { login, password },
                errors: { login: loginError, password: passwordError },
            }): FormikValues => (
                <>
                    <Layout
                        cols={1}
                        gap={scale(5, true)}
                        css={{ ...typography('inputFields'), marginTop: scale(7, true) }}
                    >
                        <Layout.Item>
                            <Form.FastField
                                isLegend
                                name="login"
                                theme="secondary"
                                placeholder="Логин"
                                label={login && !loginError && 'Логин'}
                                css={{
                                    height: scale(6),
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'end',
                                }}
                            />
                        </Layout.Item>
                        <Layout.Item>
                            <Form.FastField
                                isLegend
                                name="password"
                                placeholder="Пароль"
                                label={password && !passwordError && 'Пароль'}
                                autoComplete="on"
                                css={{
                                    height: scale(6),
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'end',
                                }}
                            >
                                <Password theme="secondary" />
                            </Form.FastField>
                        </Layout.Item>
                    </Layout>

                    <Layout
                        cols={5}
                        gap={scale(3)}
                        css={{ height: scale(7), alignItems: 'center', marginBottom: scale(1) }}
                    >
                        <Layout.Item col={3}>
                            <Form.FastField name="rememberMe">
                                <Checkbox>
                                    <span>Запомнить меня</span>
                                </Checkbox>
                            </Form.FastField>
                        </Layout.Item>

                        <Layout.Item col={2} justify="end">
                            <Button
                                role="button"
                                theme="ghost"
                                onClick={toggleForm}
                                css={{ padding: '0 !important' }}
                                data-testid="button-forgot-password"
                            >
                                <span
                                    css={{
                                        width: '100%',
                                        textAlign: 'end',
                                        color: colors?.secondary,
                                        ...typography('bodyXXSRegular'),
                                    }}
                                >
                                    Я забыл пароль
                                </span>
                            </Button>
                        </Layout.Item>
                    </Layout>

                    {errorMessage && <div css={{ color: colors.danger, marginBottom: scale(3) }}>{errorMessage}</div>}

                    <Button type="submit" block theme="secondary" size="md" data-testid="button-submit" role="button">
                        Войти
                    </Button>
                </>
            )}
        </Form>
    );
};
