import * as Yup from 'yup';

import { ErrorMessages } from '@scripts/constants';
import { CustomersTypeValues } from '@scripts/enums';
import { regPhone } from '@scripts/regex';
import validateInn from '@scripts/validateInn';

export const registerFormSchema = (activeCustomerType: CustomersTypeValues) => {
    return Yup.object().shape({
        ...(activeCustomerType === CustomersTypeValues.ENTITY && {
            inn: Yup.object()
                .shape({
                    value: Yup.string().test('inn', ErrorMessages.INN, value => validateInn(String(value))),
                    label: Yup.string(),
                })
                .nullable()
                .required(ErrorMessages.REQUIRED),
        }),

        sms_code: Yup.string().required(ErrorMessages.REQUIRED),
        phone: Yup.string().matches(regPhone, ErrorMessages.PHONE).required(ErrorMessages.REQUIRED),
        firstName: Yup.string().required(ErrorMessages.REQUIRED),
        lastName: Yup.string().required(ErrorMessages.REQUIRED),
        middleName: Yup.string().required(ErrorMessages.REQUIRED),
        email: Yup.string().email(ErrorMessages.EMAIL).required(ErrorMessages.REQUIRED),
        password: Yup.string()
            .min(6, ErrorMessages.MIN_SYMBOLS(6))
            .required(ErrorMessages.REQUIRED)
            .test('password', 'Пароль не должен содержать пробелы', value => !value?.match(/\s/gm)),
        passwordConfirmation: Yup.string()
            .min(6, ErrorMessages.MIN_SYMBOLS(6))
            .required(ErrorMessages.REQUIRED)
            .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
            .test('password', 'Пароль не должен содержать пробелы', value => !value?.match(/\s/gm)),

        agreement: Yup.boolean().required(ErrorMessages.REQUIRED).oneOf([true], ErrorMessages.REQUIRED),
    });
};

export const signInFormSchema = Yup.object().shape({
    login: Yup.string().required(ErrorMessages.REQUIRED),
    password: Yup.string()
        .min(6, ErrorMessages.MIN_SYMBOLS(6))
        .required(ErrorMessages.REQUIRED)
        .test('password', 'Пароль не должен содержать пробелы', value => !value?.match(/\s/gm)),
});

export const restorePasswordFormSchema = Yup.object().shape({
    email: Yup.string().email(ErrorMessages.EMAIL).required(ErrorMessages.REQUIRED),
});
