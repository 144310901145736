import { useCallback } from 'react';
import { QueryClient, useQueryClient } from 'react-query';

import { BASKET_DELIVERIES_QUERY_KEY, BASKET_QUERY_KEY, useBasketAddProduct, useDeleteBasket } from '@api/basket';
import { getBasket } from '@api/basket/api';
import { ProductDetail, QUERY_KEY_AUTH_OFFERS, QUERY_KEY_OFFERS } from '@api/catalog';
import { getPagination } from '@api/common/helpers';
import { CUSTOMER_PROFILE_KEY, CompareData, FAVORITE_KEY } from '@api/customers';
import { COMPARE_KEY } from '@api/customers/compare';

import { useAuth } from '@context/auth';
import { useCartContext } from '@context/cart';

import { MILISECONDS_IN_SECOND } from '@scripts/constants';

import { CURRENT_USER_QUERY_KEY } from '.';
import { apiClient } from '..';

export const AuthLocalStorageKeys = {
    TOKEN: 'auth-access-token',
    REFRESH_TOKEN: 'auth-refresh-token',
    EXPIRES_IN: 'auth-expires-in',
    EXPIRES_AT: 'auth-expires-at',
};

export interface LoginData {
    data: {
        access_token: string;
        refresh_token: string;
        expires_in: number;
    };
}

export const getAuthInfoFromLocalStorage = () => {
    const expires_at = localStorage.getItem(AuthLocalStorageKeys.EXPIRES_AT);
    const refresh_token = localStorage.getItem(AuthLocalStorageKeys.REFRESH_TOKEN) || '';
    const access_token = localStorage.getItem(AuthLocalStorageKeys.TOKEN) || '';
    return { expires_at, refresh_token, access_token };
};

export const updateAuthInfo = (data: LoginData) => {
    const { expires_in, refresh_token, access_token } = data.data;
    localStorage.setItem(
        AuthLocalStorageKeys.EXPIRES_AT,
        (Date.now() / MILISECONDS_IN_SECOND + +expires_in).toString()
    );
    localStorage.setItem(AuthLocalStorageKeys.REFRESH_TOKEN, refresh_token);
    localStorage.setItem(AuthLocalStorageKeys.TOKEN, access_token);
    localStorage.setItem(AuthLocalStorageKeys.EXPIRES_IN, expires_in.toString());
    return data;
};

export const removeAuthInfo = () => {
    localStorage.removeItem(AuthLocalStorageKeys.EXPIRES_AT);
    localStorage.removeItem(AuthLocalStorageKeys.REFRESH_TOKEN);
    localStorage.removeItem(AuthLocalStorageKeys.TOKEN);
    localStorage.removeItem(AuthLocalStorageKeys.EXPIRES_IN);
};

export async function logoutHelper(
    push: (val: string) => void,
    setUser: (val: string) => void,
    setBasketProducts: (val: []) => void,
    setComparedProducts: (val: []) => void,
    setBasketProductsServices: (val: []) => void,
    queryClient: QueryClient
) {
    await apiClient.logOut();
    queryClient.setQueryData(CURRENT_USER_QUERY_KEY, null);

    queryClient.setQueryData(
        [
            COMPARE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );
    queryClient.setQueryData(
        [
            FAVORITE_KEY,
            {
                sort: 'id',
                pagination: getPagination(-1),
            },
        ],
        []
    );

    setUser('');
    setBasketProducts([]);
    setBasketProductsServices([]);
    setComparedProducts([]);
    push('/');
}

export const useLoginHelper = () => {
    const queryClient = useQueryClient();

    const { setUser } = useAuth();
    const { setBasketProducts } = useCartContext();

    const { mutateAsync: clearBasket } = useDeleteBasket();
    const { mutateAsync: setProducts } = useBasketAddProduct();

    const loginHelper = useCallback(
        async (
            logInData: {
                login: string;
                password: string;
            },
            setIsLoading: (val: boolean) => void,
            customer_region_guid: string,
            basketProducts: ProductDetail[]
        ) => {
            setIsLoading(true);

            try {
                const data = await apiClient.logIn(logInData);

                setUser(data.data.access_token);
                setIsLoading(false);

                queryClient.invalidateQueries(CUSTOMER_PROFILE_KEY);
                queryClient.invalidateQueries(CURRENT_USER_QUERY_KEY);
                queryClient.invalidateQueries(QUERY_KEY_OFFERS);
                queryClient.invalidateQueries(QUERY_KEY_AUTH_OFFERS);
                queryClient.invalidateQueries(FAVORITE_KEY);
                queryClient.invalidateQueries(COMPARE_KEY);
                queryClient.invalidateQueries(BASKET_QUERY_KEY);

                try {
                    const basketRes = await queryClient.fetchQuery(BASKET_QUERY_KEY, () =>
                        getBasket(customer_region_guid)
                    );

                    if (!basketProducts.length) return;

                    if (basketRes && basketRes.data !== null) {
                        await clearBasket();
                    }

                    await setProducts(basketProducts.map(item => ({ offer_id: item.id, qty: item.qty })));
                    setBasketProducts([]);

                    queryClient.setQueryData(BASKET_DELIVERIES_QUERY_KEY, null);
                    queryClient.invalidateQueries(BASKET_DELIVERIES_QUERY_KEY);
                } catch (error: any) {
                    setBasketProducts([]);
                    throw new Error(error.message);
                }
            } catch (error: any) {
                setIsLoading(false);

                throw new Error(error.message);
            }
        },
        []
    );

    return { loginHelper };
};

export const changeComparedOffers = async (
    comparedProducts: ProductDetail[],
    isUser: boolean,
    deleteCompare: (val: CompareData) => void,
    createCompare: (val: CompareData) => void,
    setComparedProducts: (val: ProductDetail[]) => void,
    id: number,
    comparedAuthProductsIdsData: CompareData[],
    productInfo: ProductDetail
) => {
    const newProducts = [...comparedProducts];

    const index = newProducts.findIndex(item => item.id === id);

    if (index >= 0) {
        newProducts.splice(index, 1);
        if (isUser) {
            setComparedProducts(newProducts);
            await deleteCompare({
                id: comparedAuthProductsIdsData.find(item => item.offer_id === id)?.id,
            });
        }
    } else {
        newProducts.push(productInfo);
        if (isUser) {
            await createCompare({
                offer_id: id,
            });
        }
    }

    if (!isUser) {
        setComparedProducts(newProducts);
    }
};
