import { useQuery } from 'react-query';
import { useMutation } from 'react-query';

import { CommonResponse, CommonSearchParams } from '@api/common/types';

import { FetchError, apiClient } from '../index';
import { ProductFilter, ProductsFilterItem } from './types';
import { SearchRequestData, SearchResultByGroup } from './types/index';

const API_URL = 'catalog/offers';
const API_URL_AUTH = 'catalog/auth-offers';

export const QUERY_KEY_OFFERS = 'offers';
export const QUERY_KEY_AUTH_OFFERS = 'auth-offers';

export const productsQueryFn = (data: CommonSearchParams<Partial<ProductFilter>>) =>
    apiClient.post(`${API_URL}:search`, {
        data,
    });

export const useOffersSearch = () =>
    useMutation<CommonResponse<SearchResultByGroup>, FetchError, SearchRequestData>(data =>
        apiClient.post(`${API_URL}:suggest`, { data })
    );

export const useProductsAuthFilters = (data: CommonSearchParams<Partial<ProductFilter>>, enabled = true) =>
    useQuery<CommonResponse<ProductsFilterItem>, FetchError>({
        queryKey: [QUERY_KEY_AUTH_OFFERS, data],
        queryFn: () =>
            apiClient.post(`${API_URL_AUTH}:facets`, {
                data,
            }),
        enabled,
    });
