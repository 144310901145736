import dynamic from 'next/dynamic';
import { FC, useCallback, useState } from 'react';
import { useCityStore } from 'src/store';

import { useLoginHelper } from '@api/auth/helpers';

import { useCartContext } from '@context/cart';

import LoadWrapper from '@controls/LoadWrapper';

import { SignInForm } from '@components/Auth/SignInForm';
import Popup from '@components/controls/Popup';

import { Button, colors, scale, typography } from '@scripts/gds';

const RegisterForm = dynamic(() => import('./RegisterForm'), { ssr: false });
const RestorePasswordForm = dynamic(() => import('./RestorePasswordForm'), { ssr: false });

interface AuthTitleProps {
    registration?: boolean;
    toggleForm: () => void;
}

const AuthTitle: FC<AuthTitleProps> = ({ registration, toggleForm }) => (
    <h1
        css={{
            ...typography('h1Popup'),
            marginTop: 0,
        }}
    >
        {registration ? 'Регистрация' : 'Вход'}
        <Button theme="ghost" css={{ padding: '0 !important' }} onClick={toggleForm} data-testid="button-registration" role="button">
            <span
                css={{
                    ...typography('inputFields'),
                    color: colors.secondary,
                    marginLeft: scale(3),
                    cursor: 'pointer',
                }}
            >
                {registration ? 'Вход' : 'Регистрация'}
            </span>
        </Button>
    </h1>
);

interface AuthPopupProps {
    isOpen: boolean;
    closeHandler: () => void;
    isRegistration?: boolean;
}

const AuthPopup: FC<AuthPopupProps> = ({ isOpen, closeHandler, isRegistration = false }) => {
    const [isSignInForm, setIsSignInForm] = useState<boolean>(!isRegistration);
    const [isRestorePasswordForm, setIsRestorePasswordForm] = useState<boolean>(false);
    const [isRegistrationForm, setIsRegistrationForm] = useState<boolean>(isRegistration);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const currentCity = useCityStore(({ currentCity }) => currentCity);
    const { basketProducts } = useCartContext();

    const { loginHelper } = useLoginHelper();

    const logIn = useCallback(
        async logInData => {
            try {
                await loginHelper(logInData, setIsLoading, currentCity.region_guid, basketProducts);

                closeHandler();
                setIsSignInForm(true);
                setIsRegistrationForm(false);
                setIsRestorePasswordForm(false);
                setErrorMessage('');
            } catch (error: any) {
                setErrorMessage(error.message);
            }
        },
        [currentCity.region_guid, basketProducts]
    );

    return (
        <Popup
            isOpen={isOpen}
            onRequestClose={() => {
                closeHandler();
                setIsSignInForm(!isRegistration);
                setIsRegistrationForm(isRegistration);
                setIsRestorePasswordForm(false);
                setErrorMessage('');
            }}
            scrollInside
            isHighLevel
            css={{ width: '100vw', maxWidth: scale(51) }}
        >
            <LoadWrapper isLoading={isLoading}>
                <Popup.Body>
                    <div>
                        {isRegistrationForm && (
                            <>
                                <AuthTitle
                                    registration
                                    toggleForm={() => {
                                        setIsRegistrationForm(false);
                                        setIsSignInForm(true);
                                    }}
                                />
                                <RegisterForm logIn={logIn} closeHandler={closeHandler} />
                            </>
                        )}

                        {isSignInForm && (
                            <>
                                <AuthTitle
                                    toggleForm={() => {
                                        setIsSignInForm(false);
                                        setIsRegistrationForm(true);
                                    }}
                                />
                                <SignInForm
                                    toggleForm={() => {
                                        setIsSignInForm(false);
                                        setIsRestorePasswordForm(true);
                                    }}
                                    logIn={logIn}
                                    closeHandler={closeHandler}
                                    errorMessage={errorMessage}
                                />
                            </>
                        )}

                        {isRestorePasswordForm && (
                            <RestorePasswordForm
                                closeHandler={() => {
                                    closeHandler();
                                    setIsSignInForm(true);
                                    setIsRestorePasswordForm(false);
                                }}
                                backToSignin={() => {
                                    setIsSignInForm(true);
                                    setIsRestorePasswordForm(false);
                                }}
                            />
                        )}
                    </div>
                </Popup.Body>
            </LoadWrapper>
        </Popup>
    );
};

export default AuthPopup;
