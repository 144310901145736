import { useMemo } from 'react';

import { useCurrentUser } from '@api/auth';

import { useAuth } from '@context/auth';

export const useGetUserData = () => {
    const { user } = useAuth();
    const { data: apiUser, isIdle, error } = useCurrentUser(Boolean(user));
    const userData = useMemo(() => apiUser?.data, [apiUser]);

    return { user, apiUser, userData, isIdle, error };
};
