import { CSSObject } from '@emotion/core';
import cn from 'classnames';
import { FC, HTMLProps, ReactNode, useEffect, useRef } from 'react';
import Modal, { Props as ReactModalProps } from 'react-modal';
import { useHeaderStore } from 'src/store';

import { useBodyScrollLock } from '@context/bodyScrollLock';

import { scale, typography } from '@scripts/gds';
import { useMedia } from '@scripts/hooks';

import CrossIcon from '@icons/24/cross.svg';

import { Body, BodyProps } from './Body';
import { Footer, FooterProps } from './Footer';

export interface PopupProps extends ReactModalProps {
    /** Popup content */
    children: ReactNode | ReactNode[];
    /** Add close button */
    isCloseButton?: boolean;
    /** Fullscreen mode */
    isFullscreen?: boolean;
    /** Title text */
    title?: ReactNode;
    /** Unique name for headings association. Set id yourself for multiple popups differing */
    id?: string;
    /** Enable  or disable scroll inside, for use ID prop required */
    scrollInside?: boolean;
    /** Сss for children wrapper in popup */
    popupCss?: CSSObject;
    /** Class name */
    className?: string;
    /** Does popup appear from right */
    rightHanded?: boolean;
    /** Does popup appear from left */
    leftHanded?: boolean;
    /** Styles for catalog */
    isCatalog?: boolean;
    /** Styles for cart */
    isCart?: boolean;
    /** Styles for refund */
    isRefund?: boolean;
    /** Styles for delivery */
    isDelivery?: boolean;
    /** Scroll for overlay */
    isScrollOverlay?: boolean;
    /** Component with the biggest zIndex */
    isHighLevel?: boolean;
    /** Width&Height of Close-Button */
    closebuttonsize?: number;
}

const CloseButton: FC<HTMLProps<HTMLButtonElement> & {closebuttonsize: number}> = props => (
    <button
        aria-label="Закрыть попап"
        css={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: scale(props.closebuttonsize),
            height: scale(props.closebuttonsize),
            zIndex: 2,
            transition: 'opacity ease-in 300ms',
            ':hover': { opacity: 0.8 },
        }}
        {...props}
        type="button"
    >
        <CrossIcon />
    </button>
);

const Popup: FC<PopupProps> & { Body: FC<BodyProps>; Footer: FC<FooterProps> } = ({
    className,
    popupCss,
    children,
    onRequestClose,
    closeTimeoutMS = 300,
    isCloseButton = true,
    isFullscreen = false,
    isCatalog = false,
    isCart = false,
    isRefund = false,
    isDelivery = false,
    isScrollOverlay = false,
    title,
    scrollInside = false,
    isHighLevel = false,
    rightHanded,
    leftHanded,
    onAfterOpen,
    id = 'popup',
    isOpen,
    closebuttonsize = 9,
    ...props
}) => {
    if (rightHanded && leftHanded) {
        console.error(`You must use either rightHanded or leftHanded prop, not both at the same time`);
    }

    if (popupCss) {
        console.warn(`Use css prop on Popup component. popupCss prop will be removed`);
    }

    const { disableScroll, enableScroll } = useBodyScrollLock();
    const { sm } = useMedia();
    const baseContentClass = cn('popup-content', {
        leftHanded,
        rightHanded,
        isFullscreen,
        isCatalog,
        isCart,
        isRefund,
        isDelivery,
        scrollInside: scrollInside || rightHanded || leftHanded,
    });

    const isFixed = useHeaderStore(({ isFixed }) => isFixed);

    let overlayClass = cn('popup-overlay', { isFullscreen, isCart, leftHanded, isScrollOverlay });

    if (isCatalog) {
        overlayClass = cn('popup-overlay popup-overlay--offset', {
            isFullscreen,
            isCatalog,
            isCart,
            leftHanded,
            isScrollOverlay,
        });

        if (isFixed) {
            overlayClass = cn('popup-overlay popup-overlay--offset-custom', {
                isFullscreen,
                isCatalog,
                isCart,
                leftHanded,
                isScrollOverlay,
            });
        }
    }

    if (isHighLevel) {
        overlayClass = cn(`${overlayClass} popup-overlay--index`);
    }

    const contentRef = useRef<HTMLElement | null>(null);

    /** Enable body scroll when close popup */
    useEffect(() => {
        if (!isOpen) enableScroll();
    }, [enableScroll, isOpen]);

    return (
        <Modal
            bodyOpenClassName={null}
            contentRef={node => {
                contentRef.current = node;
            }}
            overlayClassName={{
                base: overlayClass,
                beforeClose: 'popup-overlay--before-close',
                afterOpen: 'popup-overlay--after-open',
            }}
            className={{
                base: baseContentClass,
                beforeClose: 'popup-content--before-close',
                afterOpen: 'popup-content--after-open',
            }}
            aria={{ labelledby: id }}
            closeTimeoutMS={closeTimeoutMS}
            {...(process.env.IS_STORYBOOK && { ariaHideApp: false })}
            {...props}
            onRequestClose={onRequestClose}
            onAfterOpen={e => {
                if (onAfterOpen) onAfterOpen(e);
                disableScroll();
            }}
            isOpen={isOpen}
        >
            <div
                css={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    ...((rightHanded || leftHanded) && { width: scale(42) }),
                    [sm]: {
                        maxWidth: 'initial',
                        width: '100%',
                        height: '100%',
                    },
                    ...(!isFullscreen &&
                        !isCatalog &&
                        !rightHanded &&
                        !leftHanded && { borderRadius: `${scale(1, true)}px`, maxWidth: scale(72) }),
                    ...popupCss,
                }}
                className={className}
            >
                {isCloseButton && <CloseButton closebuttonsize={closebuttonsize} onClick={onRequestClose} />}
                {title && (
                    <p
                        id={id}
                        css={{
                            padding: `${scale(3)}px ${scale(3)}px ${scale(1)}px`,
                            ...typography('h3'),
                            ...(isCloseButton && { paddingRight: scale(8) + scale(1, true) }),
                        }}
                    >
                        {title}
                    </p>
                )}
                {children}
            </div>
        </Modal>
    );
};

if (!process.env.IS_STORYBOOK) {
    Modal.setAppElement('#__next');
}

Popup.Body = Body;
Popup.Footer = Footer;

export default Popup;
