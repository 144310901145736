import { scale } from '@greensight/gds';

import { TypographyParam } from '@scripts/gds';

import tokens from '../../../public/tokens.json';

const { colors } = tokens;

export type InputTheme = {
    width?: string;

    padding?: string | number;
    paddingRight?: string | number;
    paddingLeft?: string | number;
    paddingTop?: string | number;
    paddingBottom?: string | number;
    paddingY?: number;
    height?: string | number;

    color?: string;
    disabledColor?: string;
    placeholderColor?: string;

    iconColor?: string;

    bg?: string;
    disabledBg?: string;

    typography?: TypographyParam;

    border?: string;
    borderBottom?: string;
    borderRadius?: number;
    borderColor?: string;
    disabledBorderColor?: string;
    focusBorderColor?: string;
    errorBorderColor?: string;
};

export interface InputThemes {
    [key: string]: InputTheme;
}

export const Input: InputThemes = {
    default: {
        padding: `${scale(1, true)}px ${scale(1)}px`,
        height: scale(4),

        color: colors?.grey900,
        disabledColor: colors?.grey600,
        placeholderColor: colors?.grey800,

        iconColor: colors?.grey800,

        bg: colors?.white,
        disabledBg: colors?.grey200,

        typography: 'bodySm',

        borderBottom: colors.textNuanceLight,
        borderRadius: 0,
        disabledBorderColor: colors?.grey400,
        focusBorderColor: colors?.primary,
        errorBorderColor: colors?.danger,
    },
    outline: {
        padding: `${scale(1, true)}px ${scale(1)}px`,
        height: scale(4),
        border: '1px solid #AFBCC4',
        borderRadius: 6,
        errorBorderColor: colors?.danger,
    },
    secondary: {
        width: '100%',
        paddingY: scale(1),
        placeholderColor: colors.textNuanceLight,

        border: 'none',
        borderBottom: colors.borderMain,
        errorBorderColor: colors?.danger,
        bg: 'transparent',
    },

    calendar: {
        border: 'none',
    },

    checkout: {
        padding: `0 ${scale(3)}px 0 ${scale(2)}px`,
        height: scale(6),
        border: '1px solid #1341B0',
        borderRadius: 6,
        errorBorderColor: colors?.danger,
    }
};
